import React from 'react'
import { graphql } from 'gatsby'
import Space from '@rebass/space'
import { Flex, Box } from 'reflexbox'
import Img from 'gatsby-image'
import Container from '../components/Container'
import GradientWrapper from '../components/GradientWrapper'
import SEO from '../components/SEO'
import Badge from '../components/Badge'
import Card from '../components/Card'
import Button from '../components/Button'
import { ThemeConsumer } from 'styled-components'
import FeatureImprovement from '../components/FeatureImprovement'
import BugFix from '../components/BugFix'
import { TwitterTweetEmbed } from 'react-twitter-embed'

// Icons
import IconRecentlyUsed from '../images/vector/icon-recent-small.svg'
import IconStar from '../images/vector/icon-star.svg'
import IconSmartSet from '../images/vector/icon-smartset.svg'
import IconHex from '../images/vector/hex.svg'
import IconSFSymbols from '../images/vector/sf-symbols.svg'
import IconFont from '../images/vector/icon-font.svg'
import IconAdvancedSearch from '../images/vector/icon-advanced-search.svg'
import IconSVG from '../images/vector/svg.svg'
import IconNew from '../images/vector/icon-new.svg'
import IconBeta from '../images/vector/icon-beta.svg'

// Videos
import StrokeOptionsVideo from '../images/updates/iconjar2-8.mp4'
import MultiSelectColorPickerVideo from '../images/updates/iconjar2-8-1.mp4'
import ResizableInspector from '../images/updates/iconjar2-9.mp4'

const Changelog = ({ data }) => {
  return (
    <ThemeConsumer>
      {theme => (
        <>
          <SEO title="Updates • What's new in IconJar" />

          {/* Hero */}
          <Container my={[4, 5]}>
            <Box textAlign={'center'}>
              <h1>Updates</h1>
            </Box>
          </Container>

          <Container my={[4, 5]}>
            <Flex
              flexWrap="wrap"
              alignItems="flex-start"
              justifyContent="center">
              <Box width={[1, 2 / 3]} px={[0, 3, 3]}>
                {/* Content */}

                <Card p={4} mb={5}>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap="wrap">
                    <Box width={[1, 1, 2 / 3, 3 / 4]}>
                      <Space mr={[0, 0, 3]}>
                        <h4>Looking for a specific feature?</h4>
                        <p className="small">
                          We'd love to hear your ideas for improving our
                          product!
                        </p>
                      </Space>
                    </Box>
                    <Box
                      width={[1, 1, 1 / 3, 1 / 4]}
                      mt={[3, 3, 0]}
                      textAlign={['left', 'left', 'right', 'right']}>
                      <Button
                        as="a"
                        href="mailto:info@geticonjar.com"
                        role="button">
                        Request feature
                      </Button>
                    </Box>
                  </Flex>
                </Card>

                <Box mb={4} id="latest">
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.11.3
                    </h2>
                  </Space>
                  <Box mb={4}>
                    <Badge colorscheme="orange" mb={3}>
                      Improvements
                    </Badge>
                    <FeatureImprovement>
                      We've improved the performance of our IJSVG library
                    </FeatureImprovement>
                  </Box>
                  <Box mb={4}>
                    <Badge colorscheme="red" mb={3}>
                      Bug fixes
                    </Badge>
                    <BugFix>
                      We fixed a couple of bugs and IconJar is now compatible
                      with macOS Sequoia
                    </BugFix>
                    <BugFix>
                      Various memory leaks were fixed in IJSVG and certain icons
                      will now render correctly
                    </BugFix>
                  </Box>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.11.2
                    </h2>
                  </Space>
                  <Box>
                    <Badge colorscheme="red" mb={3}>
                      Bug fixes
                    </Badge>
                    <BugFix>
                      Fixes rendering of WebP images and exporting them,
                      QuickDrag now also obeys the quality setting for WebP
                      images.
                    </BugFix>
                  </Box>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.11.1
                    </h2>
                  </Space>
                  <Box>
                    <Badge colorscheme="red" mb={3}>
                      Bug fixes
                    </Badge>
                    <BugFix>Fixes rendering issues with some gradients</BugFix>
                  </Box>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.11
                    </h2>
                  </Space>

                  <Box mb={4}>
                    <Badge colorscheme="orange" mb={3}>
                      Improvements
                    </Badge>
                    <FeatureImprovement>
                      We've completely rewritten our SVG library IJSVG. Learn
                      more on its{' '}
                      <a href="https://github.com/IconJar/IJSVG">GitHub page</a>
                      .
                    </FeatureImprovement>
                  </Box>
                  <Box>
                    <Badge colorscheme="red" mb={3}>
                      Bug fixes
                    </Badge>
                    <BugFix>
                      Fixed an issue where the grid would resort itself when
                      navigating icons with names that begin with a whitespace
                    </BugFix>
                    <BugFix>
                      We have also fixed a crash on launch for some users
                    </BugFix>
                  </Box>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.10
                    </h2>
                  </Space>

                  <Space mb={4}>
                    <Img
                      fluid={data.twoTen.childImageSharp.fluid}
                      alt="IconJar 2.10"
                      style={{ borderRadius: '6px' }}
                    />
                  </Space>

                  <Box mb={4}>
                    <Badge colorscheme="orange" mb={3}>
                      Improvements
                    </Badge>
                    <FeatureImprovement>
                      New UI for macOS 10.16+
                    </FeatureImprovement>
                    <FeatureImprovement>
                      Inspector and set list will now overlay when collapsed in
                      full screen where applicable.
                    </FeatureImprovement>
                    <FeatureImprovement>
                      Added ability to export specific colors as currentColor in
                      Quickdrag, also in the exporter and SVG Cleaner.
                      <Box style={{ maxWidth: '322px' }}>
                        <Space mt={2}>
                          <Img
                            fluid={data.currentColor.childImageSharp.fluid}
                            alt="IconJar CSS currentColor"
                            style={{ borderRadius: '6px' }}
                          />
                        </Space>
                      </Box>
                    </FeatureImprovement>
                    <FeatureImprovement>
                      Added reset to default button to color picker, along with
                      various fixes to it.
                    </FeatureImprovement>
                    <FeatureImprovement>
                      Added duplicate set / group function.
                    </FeatureImprovement>
                    <FeatureImprovement>
                      Grid view has been refactored and is now much more
                      performant and consumed much less memory
                    </FeatureImprovement>
                  </Box>
                  <Box>
                    <Badge colorscheme="red" mb={3}>
                      Bug fixes
                    </Badge>
                    <BugFix>
                      Fixes issue with color picker sometimes showing incorrect
                      controls
                    </BugFix>
                    <BugFix>Fixes various SVG rendering issues</BugFix>
                    <BugFix>
                      Fixes various selection issues in the set list
                    </BugFix>
                    <BugFix>
                      Fixes bitmap quality not being set by default
                    </BugFix>
                  </Box>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.9
                    </h2>
                  </Space>

                  <Box mb={4}>
                    <Badge colorscheme="green" mb={3}>
                      New
                    </Badge>
                    <Flex alignItems={'flex-start'} mb={3}>
                      <Box style={{ minWidth: '22px' }} mr={2}>
                        <IconSVG width="24" />
                      </Box>
                      <Box>
                        <p>
                          <strong>Change SVG stroke properties</strong>
                          <br />
                          You can now adjust the stroke thickness, line cap and
                          joins of your SVGs directly in IconJar, as shown in
                          the video below.
                        </p>
                        <Space mt={3}>
                          <video
                            loop={true}
                            autoPlay="autoplay"
                            muted
                            playsInline
                            style={{
                              borderRadius: '6px',
                              width: '480px',
                              display: 'block'
                            }}>
                            <source src={ResizableInspector} type="video/mp4" />
                          </video>
                        </Space>
                      </Box>
                    </Flex>
                  </Box>

                  <Box mb={4}>
                    <Badge colorscheme="orange" mb={3}>
                      Improvements
                    </Badge>
                    <FeatureImprovement>
                      Added Sort by Name Recursively to Groups in the list (this
                      works at top level as-well as any group
                    </FeatureImprovement>
                    <FeatureImprovement>
                      Quickdrag will now scale in width and height when residing
                      the inspector, we have also allowed the inspector to be
                      made wider
                    </FeatureImprovement>
                    <FeatureImprovement>
                      When dragging and icon in fullscreen and IconJar is told
                      to hide, it will focus the previous used app (this may
                      cause the space to animate to it)
                    </FeatureImprovement>
                    <FeatureImprovement>
                      IconJar will now ask you if you want to override or keep
                      existing icons on disk when exporting icons with the same
                      name
                    </FeatureImprovement>
                    <FeatureImprovement>
                      When zooming / changing the grid size, you will now get
                      haptic feedback when reaching predefined / standard sizes
                    </FeatureImprovement>
                  </Box>
                  <Box>
                    <Badge colorscheme="red" mb={3}>
                      Bug fixes
                    </Badge>
                    <BugFix>
                      Updated Paddle SDK (this fixes some memory leaks)
                    </BugFix>
                    <BugFix>
                      Fixed issue where sets were not scrolled into view when
                      selected or imported in the set list
                    </BugFix>
                    <BugFix>
                      Fixed issue where you could not drop a folder into
                      subfolders from Finder into the set list
                    </BugFix>
                    <BugFix>Fixes a crash when changing filter type</BugFix>
                  </Box>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4} id="latest">
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.8.1
                    </h2>
                  </Space>

                  <Box mb={4}>
                    <Badge colorscheme="orange" mb={3}>
                      Improvements
                    </Badge>
                    <FeatureImprovement>
                      Our color picker now lets you switch between "all" and
                      "shared" colors to control the (number of) colors shown in
                      the dropdown when you have multiple icons selected with
                      differing colors.
                      <Space mt={3}>
                        <video
                          loop={true}
                          autoPlay="autoplay"
                          muted
                          playsInline
                          style={{
                            borderRadius: '6px',
                            width: '480px',
                            display: 'block'
                          }}>
                          <source
                            src={MultiSelectColorPickerVideo}
                            type="video/mp4"
                          />
                        </video>
                      </Space>
                    </FeatureImprovement>
                  </Box>
                  <Box>
                    <Badge colorscheme="red" mb={3}>
                      Bug fixes
                    </Badge>
                    <BugFix>
                      Fixes a rare crash when selecting multiple icons.{' '}
                    </BugFix>
                  </Box>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.8
                    </h2>
                  </Space>

                  <Space mb={4}>
                    <video
                      loop={true}
                      autoPlay="autoplay"
                      muted
                      playsInline
                      style={{ borderRadius: '6px' }}>
                      <source src={StrokeOptionsVideo} type="video/mp4" />
                    </video>
                  </Space>
                  <Box mb={4}>
                    <Badge colorscheme="blue" mb={3}>
                      In beta
                    </Badge>
                    <Flex alignItems={'flex-start'} mb={3}>
                      <Box style={{ minWidth: '22px' }} mr={2}>
                        <IconBeta width="24" />
                      </Box>
                      <Box>
                        <p>
                          <strong>Change SVG stroke properties</strong>
                          <br />
                          You can now adjust the stroke thickness, line cap and
                          joins of your SVGs directly in IconJar, as shown in
                          the video above. You can opt-in by going to
                          preferences &rarr; advanced &rarr; and switch on the
                          "Enable experimental features" setting.
                        </p>
                      </Box>
                    </Flex>

                    <Badge colorscheme="green" mb={3}>
                      New
                    </Badge>
                    <Flex alignItems={'flex-start'} mb={3}>
                      <Box style={{ minWidth: '22px' }} mr={2}>
                        <IconNew width="24" />
                      </Box>
                      <Box>
                        <p>
                          <strong>Added Finder services</strong>
                          <br />
                          Our app now offers services like "cleanup SVG with
                          IconJar" to contextual menus in the Finder to further
                          enhance your workflows.
                        </p>
                      </Box>
                    </Flex>
                    <Badge colorscheme="orange" mb={3}>
                      Improvements
                    </Badge>
                    <FeatureImprovement>
                      We now group colours in our colour picker when your
                      selection contains more than one colour.
                    </FeatureImprovement>
                    <FeatureImprovement>
                      You can now toggle the grid tools overlay.
                    </FeatureImprovement>
                    <FeatureImprovement>
                      Various performance and visual improvements throughout the
                      app.
                    </FeatureImprovement>
                    <FeatureImprovement>
                      Vastly reduced memory usage when importing.
                    </FeatureImprovement>
                  </Box>
                  <Box>
                    <Badge colorscheme="red" mb={3}>
                      Bug fixes
                    </Badge>
                    <BugFix>Fixes crash on import.</BugFix>
                    <BugFix>Fixed various rendering issues with SVGs.</BugFix>
                  </Box>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4} id="latest">
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.7.3
                    </h2>
                  </Space>

                  <Box mb={4}>
                    <Badge colorscheme="orange" mb={3}>
                      Improvements
                    </Badge>
                    <FeatureImprovement>
                      When “hide main window when dragging icons” is enabled, we
                      will now move the window to the back instead of closing
                      it.
                    </FeatureImprovement>
                  </Box>
                  <Box>
                    <Badge colorscheme="red" mb={3}>
                      Bug fixes
                    </Badge>
                    <BugFix>
                      The export icon shown on our onboarding splash screen now
                      renders correctly.
                    </BugFix>
                    <BugFix>
                      We fixed a crash that was a caused by a memory overflow
                      when parsing polygons or lines within SVG’s.
                    </BugFix>
                  </Box>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.7.2
                    </h2>
                  </Space>

                  <Box mb={4}>
                    <Badge colorscheme="orange" mb={3}>
                      Improvements
                    </Badge>
                    <FeatureImprovement>
                      We have updated our SVG rendering library to the latest
                      build, including a vast amount of performance and memory
                      improvements.
                    </FeatureImprovement>
                  </Box>
                  <Box>
                    <Badge colorscheme="red" mb={3}>
                      Bug fixes
                    </Badge>
                    <BugFix>
                      We fixed an issue where IconJar would crash after
                      importing specific icon fonts.
                    </BugFix>
                    <BugFix>
                      Paddle only: Fixes issue with XPC service not running
                      under ARM.
                    </BugFix>
                  </Box>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.7.1
                    </h2>
                  </Space>

                  <Box mb={4}>
                    <Badge colorscheme="orange" mb={3}>
                      Improvements
                    </Badge>
                    <TwitterTweetEmbed tweetId={'1351636329063444480'} />
                    <FeatureImprovement>
                      Our empty states now have a new design that's more
                      consistent with macOS Big Sur.
                    </FeatureImprovement>
                    <FeatureImprovement>
                      QuickDrag preview is now correctly sorted based on your
                      selection in the grid.
                    </FeatureImprovement>
                    <FeatureImprovement>
                      Massive performance increase for showing counts of large
                      libraries. We also toned down the contrast on the count in
                      the set list to reduce visual clutter.
                    </FeatureImprovement>
                    <FeatureImprovement>
                      Added View in set to icons in Starred and Recently used
                      set.
                    </FeatureImprovement>
                    <FeatureImprovement>
                      Improved SVG compatibility and performance.
                    </FeatureImprovement>
                  </Box>
                  <Box>
                    <Badge colorscheme="red" mb={3}>
                      Bug fixes
                    </Badge>
                    <BugFix>
                      The app now shows the correct file size when you download
                      an IconJar update, or pull Material Icons from our
                      servers.
                    </BugFix>
                    <BugFix>
                      Fixes issue that created empty groups at the root level
                      when ungrouping sets.
                    </BugFix>
                    <BugFix>
                      Fixes issue that created empty sets at the root level when
                      merging multiple sets.
                    </BugFix>
                    <BugFix>
                      Fixes crash that could occur when folder names contained a
                      multibit string.
                    </BugFix>
                    <BugFix>
                      Fixes various UI issues related to macOS Big Sur.
                    </BugFix>
                    <BugFix>
                      Fixes issue where IconJar would ask you to migrate on
                      fresh install when there is nothing to migrate from.
                    </BugFix>
                    <BugFix>
                      Fixes issue where converting a font to SVG would use the
                      compression options and cause issues with rendering.
                    </BugFix>
                    <BugFix>
                      Fixes issue where exported items via the sheet were not
                      added to recently used.
                    </BugFix>
                    <BugFix>
                      Fixes issue where the Set view would scroll off the top of
                      the window.
                    </BugFix>
                    <BugFix>
                      Fixes set counts not updating correctly when icons were
                      being removed.
                    </BugFix>
                    <BugFix>
                      Fixes issue where sometimes icon previews would not load
                      in the grid.
                    </BugFix>
                    <BugFix>
                      IconJar no longer shows a notification to import SF
                      Symbols (as it no longer worked due to updates Apple made
                      to the SF Symbols app).
                    </BugFix>
                  </Box>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.7
                    </h2>
                  </Space>

                  <Space mb={4}>
                    <Img
                      fluid={data.BigSur.childImageSharp.fluid}
                      alt="IconJar 2.7"
                      style={{ borderRadius: '6px' }}
                    />
                  </Space>

                  <Box mb={4}>
                    <Badge colorscheme="green" mb={3}>
                      New features
                    </Badge>
                    <Flex alignItems={'flex-start'} mb={3}>
                      <Box style={{ minWidth: '22px' }} mr={2}>
                        <IconNew width="24" />
                      </Box>
                      <Box>
                        <p>
                          <strong>
                            Use the title and desc elements of SVGs to apply
                            name, tags and description to icons
                          </strong>
                          <br />
                          This can be enabled in Preferences &rarr; Import
                          &rarr; Use SVG title and desc when available. The app
                          will fallback to using the file name if these aren't
                          found in an SVG.
                        </p>
                      </Box>
                    </Flex>
                  </Box>

                  <Flex flexWrap="wrap">
                    <Box mb={4}>
                      <Badge colorscheme="orange" mb={3}>
                        Improvements
                      </Badge>
                      <FeatureImprovement>
                        IconJar's UI has been updated to match the beautiful
                        design of macOS Big Sur.
                      </FeatureImprovement>
                      <FeatureImprovement>
                        We've added support for Apple's new M1 processor.
                      </FeatureImprovement>
                    </Box>
                    <Box>
                      <Badge colorscheme="red" mb={3}>
                        Bug fixes
                      </Badge>
                      <BugFix>
                        Backup libraries are now marked as a backup correctly.
                      </BugFix>
                      <BugFix>
                        Window tint now works properly for the Preferences,
                        licenses and SVG cleaner windows.
                      </BugFix>
                    </Box>
                  </Flex>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.6.x
                    </h2>
                  </Space>
                  <Box>
                    <Badge colorscheme="red" mb={3}>
                      Bug fixes
                    </Badge>
                    <BugFix>
                      Fixes licensing filter not working correctly
                    </BugFix>
                    <BugFix>
                      Fixes crash when licensing filter is applied and search is
                      performed
                    </BugFix>
                    <BugFix>
                      Fixed issue with backing up of libraries that would
                      sometimes crash{' '}
                    </BugFix>
                  </Box>
                </Box>

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.6
                    </h2>
                  </Space>

                  <Space mb={4}>
                    <Img
                      fluid={data.SVGCleaner.childImageSharp.fluid}
                      alt="IconJar 2.6"
                      style={{ borderRadius: '6px' }}
                    />
                  </Space>

                  <Box mb={4}>
                    <Badge colorscheme="green" mb={3}>
                      New features
                    </Badge>
                    <Flex alignItems={'flex-start'} mb={3}>
                      <Box style={{ minWidth: '22px' }} mr={2}>
                        <IconSVG width="24" />
                      </Box>
                      <Box>
                        <p>
                          <strong>SVG Cleaner</strong>
                          <br />
                          You can now clean SVGs without importing the files
                          into IconJar first. You can launch the window from
                          Tools &rarr; SVG Cleaner (beta).
                        </p>
                      </Box>
                    </Flex>
                    <Flex alignItems={'flex-start'}>
                      <Box style={{ minWidth: '22px' }} mr={2}>
                        <IconNew width="24" />
                      </Box>
                      <Box>
                        <p>
                          <strong>New app icon</strong>
                          <br />
                          We've proactively updated our app icon to match macOS
                          Big Sur's visual language. We hope you like it!
                        </p>
                      </Box>
                    </Flex>
                  </Box>

                  <Flex flexWrap="wrap">
                    <Box mb={4}>
                      <Badge colorscheme="orange" mb={3}>
                        Improvements
                      </Badge>
                      <FeatureImprovement>
                        We've added various options for optimizing SVGs on
                        export, such as:
                        <ul style={{ marginTop: '1rem' }}>
                          <li>Round transforms (with precision options)</li>
                          <li>Convert shapes to paths</li>
                          <li>Convert curves to arcs</li>
                          <li>Remove default values</li>
                          <li>Remove XML declaration</li>
                        </ul>
                      </FeatureImprovement>
                      <FeatureImprovement>
                        We now link to our Sketch plugin from Tools &rarr; Get
                        our Sketch Plugin&#8230;.
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Export now allows IconJar’s set hierarchy to be
                        respected.
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Quicklook now shows the file name and size.
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Our post-registration window now shows a few characters
                        of the license key.
                      </FeatureImprovement>
                    </Box>
                    <Box>
                      <Badge colorscheme="red" mb={3}>
                        Bug fixes
                      </Badge>
                      <BugFix>
                        We fixed an issue for smart sets that caused no icons to
                        match.
                      </BugFix>
                      <BugFix>
                        We Fixed issue where using a decimal place in suffix or
                        prefix broke the file name on export.
                      </BugFix>
                    </Box>
                  </Flex>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.5
                    </h2>
                    <p>
                      This version of IconJar is packed with improvements to
                      some of our core features, such as QuickDrag, library
                      backups. Additionally we've made a bunch of accessibility
                      improvements throughout the entire app.
                    </p>
                  </Space>

                  <Space mb={4}>
                    <Img
                      fluid={data.Bups.childImageSharp.fluid}
                      alt="IconJar 2.5"
                      style={{ borderRadius: '6px' }}
                    />
                  </Space>

                  <Flex flexWrap="wrap">
                    <Box mb={3}>
                      <Badge colorscheme="orange" mb={3}>
                        Improvements
                      </Badge>
                      <FeatureImprovement>
                        Automatic backup cleaning - This can be enabled within
                        the preferences
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Enhancements to how we work out set counts
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Enhancements on Gridview, larger section headers and
                        refined margins
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Reduced memory usage (both system memory and video
                        memory)
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Now implements full vibrancy and themes for macOS
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Fixes and improvements to Recently Used set
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Added "New Group" onto a Groups contextual menu
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Adding pagination to QuickDrag
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Performance increases throughout the application — lots
                        of work done to improve the grid scrolling performance
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Completely rewritten how we load images in to reduce CPU
                        and memory.
                      </FeatureImprovement>
                    </Box>
                    <Box>
                      <Badge colorscheme="red" mb={3}>
                        Bug fixes
                      </Badge>
                      <BugFix>Fixes subfolders not being removed</BugFix>
                      <BugFix>Fixes for macOS Big Sur (10.16/11)</BugFix>
                      <BugFix>
                        Fixes issue where icon was copied to a new set and the
                        new set name field within the list was still visible
                        upon the set updating its count
                      </BugFix>
                      <BugFix>
                        Fixed issue where upon launch of IconJar the grid would
                        be incorrectly sized.
                      </BugFix>
                    </Box>
                  </Flex>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.4.1
                    </h2>
                  </Space>

                  <Box>
                    <Badge colorscheme="red" mb={3}>
                      Bug fixes
                    </Badge>
                    <BugFix>
                      Fixed an issue that deleted icons from their original sets
                      when deleting Smart Sets.
                    </BugFix>
                  </Box>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.4
                    </h2>
                  </Space>

                  <Space mb={4}>
                    <Img
                      fluid={data.RegEx.childImageSharp.fluid}
                      alt="IconJar 2.4"
                      style={{ borderRadius: '6px' }}
                    />
                  </Space>

                  <Flex flexWrap="wrap">
                    <Box mb={3}>
                      <Badge colorscheme="green" mb={3}>
                        New features
                      </Badge>
                      <Flex alignItems={'flex-start'}>
                        <Box style={{ minWidth: '22px' }} mr={2}>
                          <IconAdvancedSearch />
                        </Box>
                        <Box>
                          <p>
                            <strong>
                              Support for Regex and wildcard search queries
                            </strong>
                            <br />
                            Added ability to change search modes, we now include
                            RegExp and Wildcard. This functionality has also
                            been added to Smart Sets.
                          </p>
                        </Box>
                      </Flex>
                    </Box>
                    <Box mb={3}>
                      <Badge colorscheme="orange" mb={3}>
                        Improvements
                      </Badge>
                      <FeatureImprovement>
                        We added the ability to disable the double clicking of
                        icons within the grid.
                      </FeatureImprovement>
                      <FeatureImprovement>
                        You can now cancel finding of duplicate icons.
                      </FeatureImprovement>
                    </Box>
                    <Box>
                      <Badge colorscheme="red" mb={3}>
                        Bug fixes
                      </Badge>
                      <BugFix>
                        Fixed crash when searching whilst recently used set is
                        selected.
                      </BugFix>
                      <BugFix>
                        Fixed issue where export presets were not saving and
                        restoring correctly.
                      </BugFix>
                      <BugFix>
                        Fixed various SVG rendering issues where masks were
                        involved.
                      </BugFix>
                      <BugFix>
                        Fixed issue where upon launch of IconJar the grid would
                        be incorrectly sized.
                      </BugFix>
                    </Box>
                  </Flex>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.3
                    </h2>
                  </Space>

                  <Space mb={4}>
                    <Img
                      fluid={data.SearchProperties.childImageSharp.fluid}
                      alt="IconJar 2.3"
                      style={{ borderRadius: '6px' }}
                    />
                  </Space>

                  <Flex flexWrap="wrap">
                    <Box mb={3}>
                      <Badge colorscheme="green" mb={3}>
                        New features
                      </Badge>
                      <Flex alignItems={'flex-start'}>
                        <Box style={{ minWidth: '32px' }} mr={2}>
                          <IconAdvancedSearch />
                        </Box>
                        <Box>
                          <p>
                            <strong>Additional filters</strong>
                            <br />
                            We've added filters to our search input so you can
                            really narrow down the search results to what you're
                            looking for.
                          </p>
                        </Box>
                      </Flex>
                    </Box>
                    <Box mb={3}>
                      <Badge colorscheme="orange" mb={3}>
                        Improvements
                      </Badge>
                      <FeatureImprovement>
                        We've added an option to show the # of icons per set in
                        the left sidebar. This can be enabled under view → Show
                        Set Counts
                      </FeatureImprovement>
                      <FeatureImprovement>
                        We now check for IconJar 1 licenses when activating
                        IconJar 2 so we can show a relevant error message
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Numerous improvements to IJSVG, our custom built SVG
                        renderer for macOS
                      </FeatureImprovement>
                    </Box>
                    <Box>
                      <Badge colorscheme="red" mb={3}>
                        Bug fixes
                      </Badge>
                      <BugFix>
                        The icon's label in the grid now updates accordingly.
                      </BugFix>
                    </Box>
                  </Flex>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.2
                    </h2>
                  </Space>

                  <Flex flexWrap="wrap">
                    <Badge colorscheme="green" mb={3}>
                      New features
                    </Badge>
                    <Flex alignItems={'flex-start'} mb={3}>
                      <Box style={{ minWidth: '32px' }} mr={2}>
                        <IconFont />
                      </Box>
                      <Box>
                        <p>
                          <strong>Improved support for icon fonts</strong>
                          <br />
                          We now keep a reference to icon imported icon fonts
                          and register them with macOS. We fetch the icon's name
                          from the font, and allow its characters to be copied
                          as rich text.
                        </p>
                      </Box>
                    </Flex>
                    <Box mb={3}>
                      <Badge colorscheme="orange" mb={3}>
                        Improvements
                      </Badge>
                      <FeatureImprovement>
                        SVG Exporting will now obbey the original SVG's
                        primitive shape (if it was one). IconJar used to convert
                        any primitive shape into path data, but it will now
                        respect what it was originally and write it back where
                        it can.
                      </FeatureImprovement>
                      <FeatureImprovement>
                        SVG's can now be exported without comments.
                      </FeatureImprovement>
                      <FeatureImprovement>
                        You can now export icons with their associated tags as
                        their filename.
                      </FeatureImprovement>
                      <FeatureImprovement>
                        You can now center icons within their viewbox when
                        exporting as SVG.
                      </FeatureImprovement>
                      <FeatureImprovement>
                        EPS export is infinitely better and will maintain vector
                        when exporting vector files.
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Improved SVG rendering and parsing performance
                        considerably,{' '}
                        <strong>
                          parsing is now up to 8x faster and with reduction of
                          memory.
                        </strong>
                      </FeatureImprovement>
                      <FeatureImprovement>
                        SVG export options have been separated out into basic
                        and advanced options.
                      </FeatureImprovement>
                      <FeatureImprovement>
                        SVG are now reduced in size when exporting, this is
                        achieved by finetuning how we write exported path data
                        and floats.
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Open in &hellip; list is now sorted correctly.
                      </FeatureImprovement>
                      <FeatureImprovement>
                        You can now specify a background color for exported
                        bitmaps.
                      </FeatureImprovement>
                    </Box>
                    <Box>
                      <Badge colorscheme="red" mb={3}>
                        Bug fixes
                      </Badge>
                      <BugFix>
                        Fixes SVG parsing of compressed ellipical arc commands.
                      </BugFix>
                      <BugFix>Bootstrap Icons now render correctly.</BugFix>
                      <BugFix>
                        Fixes various memory leaks related to SVG and gradients.
                      </BugFix>
                      <BugFix>
                        Fixes various memory buffer overflows within SVG parser
                      </BugFix>
                      <BugFix>
                        Fixes a bug when exporting an IconJar that some files
                        were overwritten if they were the same name from
                        multiple sets.
                      </BugFix>
                    </Box>
                  </Flex>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Box mb={3}>
                    <Box>
                      <h2
                        style={{
                          display: 'inline-block',
                          verticalAlign: 'middle',
                          marginRight: '16px'
                        }}>
                        Version 2.1.1
                      </h2>
                    </Box>
                  </Box>

                  <Flex flexWrap="wrap">
                    <Box>
                      <BugFix>
                        This release fixes a rare issue where an IconJar 2
                        library would show up empty after migrating from v1.
                      </BugFix>
                    </Box>
                  </Flex>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box mb={4}>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.1
                    </h2>
                  </Space>

                  <Flex flexWrap="wrap">
                    <Box mb={3}>
                      <Badge colorscheme="green" mb={3}>
                        New features
                      </Badge>
                      <Flex alignItems={'flex-start'}>
                        <Box style={{ minWidth: '32px' }} mr={2}>
                          <IconSFSymbols />
                        </Box>
                        <Box>
                          <p>
                            <strong>
                              Supports for Apple's SF Symbols icons
                            </strong>
                            <br />
                            Import all the different weights of{' '}
                            <a
                              href="https://developer.apple.com/design/human-interface-guidelines/sf-symbols/overview/"
                              rel="nofollow">
                              Apple's official SF Symbol icons
                            </a>{' '}
                            into IconJar with just one click.
                          </p>
                        </Box>
                      </Flex>
                      <Flex alignItems={'flex-start'}>
                        <Box style={{ minWidth: '32px' }} mr={2}>
                          <IconHex />
                        </Box>
                        <Box>
                          <p>
                            <strong>
                              Support for HEX colors with 8 characters
                            </strong>
                            <br />
                            We've added support for alpha in HEX strings to
                            IconJar.
                          </p>
                        </Box>
                      </Flex>
                    </Box>
                    <Box>
                      <Badge colorscheme="orange" mb={3}>
                        Improvements
                      </Badge>
                      <FeatureImprovement>
                        Adds states for toolbar icons for Filter and Starred
                      </FeatureImprovement>
                      <BugFix>
                        Fixes a few rendering issues with some SVG’s
                      </BugFix>
                      <BugFix>
                        Fixes issue where a backup would happen upon migration
                      </BugFix>
                      <BugFix>Fixes various memory leaks</BugFix>
                    </Box>
                  </Flex>
                </Box>

                <hr style={{ marginBottom: '2rem' }} />

                <Box>
                  <Space mb={3}>
                    <h2
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '16px'
                      }}>
                      Version 2.0
                    </h2>
                  </Space>

                  <Flex flexWrap="wrap" mb={5}>
                    <Badge colorscheme="green" mb={3}>
                      New features
                    </Badge>
                    <Box mb={3}>
                      <Flex alignItems={'flex-start'}>
                        <Box style={{ minWidth: '32px' }} mr={2}>
                          <IconSmartSet />
                        </Box>
                        <Box>
                          <p>
                            <strong>
                              Bundle similar icons with Smart Sets
                            </strong>
                            <br />
                            IconJar now lets you create rule based sets. Smart
                            sets are great for when you have a favourite style,
                            prefer a specific file type, or want to show every
                            icon with a certain license.
                          </p>
                        </Box>
                      </Flex>
                      <Flex alignItems={'flex-start'}>
                        <Box style={{ minWidth: '32px' }} mr={2}>
                          <IconRecentlyUsed
                            style={{
                              fill:
                                theme.appearance === 'light'
                                  ? '#31383C'
                                  : '#FFF'
                            }}
                          />
                        </Box>
                        <Box>
                          <p>
                            <strong>View recently used icons</strong>
                            <br />
                            IconJar automatically adds the icons you've used to
                            a recently used set in the left sidebar.
                          </p>
                        </Box>
                      </Flex>
                      <Flex alignItems={'flex-start'}>
                        <Box style={{ minWidth: '32px' }} mr={2}>
                          <IconStar />
                        </Box>
                        <Box>
                          <p>
                            <strong>Star your favourite icons</strong>
                            <br />
                            You can now star the icons you like the most.
                            Starred icons are all grouped in one set so you
                            never have to search for them.
                          </p>
                        </Box>
                      </Flex>
                    </Box>
                    <Box>
                      <Badge colorscheme="orange" mb={3}>
                        Improvements
                      </Badge>
                      <FeatureImprovement>
                        Full compatability with macOS 10.15 Catalina
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Our color picker now includes an eye-dropper tool
                      </FeatureImprovement>
                      <FeatureImprovement>
                        UI changes througout the whole app
                      </FeatureImprovement>
                      <FeatureImprovement>
                        You can now also change the color of gradients
                      </FeatureImprovement>
                      <FeatureImprovement>
                        You can now have IconJar search for, and clean up,
                        duplicate icons
                      </FeatureImprovement>
                      <FeatureImprovement>
                        You can now filter your icon set list by keyword
                      </FeatureImprovement>
                      <FeatureImprovement>
                        We now link out to licenses from the inspector when a
                        url for the license is provided
                      </FeatureImprovement>
                      <FeatureImprovement>
                        We now allow users to opt-in for beta releases
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Trigger search with a keystroke with “always active
                        search”
                      </FeatureImprovement>
                      <FeatureImprovement>
                        We have implemented basic Touchbar support for the grid
                      </FeatureImprovement>
                      <FeatureImprovement>
                        You can now sort the grid
                      </FeatureImprovement>
                      <FeatureImprovement>
                        You can choose to have the grid grouped by set or not
                      </FeatureImprovement>
                      <FeatureImprovement>
                        You can now drag icons and iconjar files directly into
                        the set list for inplace adding
                      </FeatureImprovement>
                      <FeatureImprovement>
                        You can now drag out sets from the list and instantly
                        create iconjar files
                      </FeatureImprovement>
                      <FeatureImprovement>
                        We have added SVG export options to the preferences to
                        customize SVG processing
                      </FeatureImprovement>
                      <FeatureImprovement>
                        You can now improve icon constrast when using Aqua
                        instead of just in Dark mode
                      </FeatureImprovement>
                      <FeatureImprovement>
                        If moving a library when IconJar is not open, we keep
                        track of it and wont need to refind it again
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Managing licenses has now been given its own window
                        instead of inside Preferences
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Quicklook has been simplified and is detachable, this
                        also respects the grid dark mode
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Grid can now display up to 256pt sizes
                      </FeatureImprovement>
                      <FeatureImprovement>
                        Updated IJSVG to fix a lot of rendering issues with
                        gradients
                      </FeatureImprovement>
                      <FeatureImprovement>
                        You can now right click and copy, or copy code from
                        Quickdrag
                      </FeatureImprovement>
                      <FeatureImprovement>
                        The grid size controls now overlay the grid but are
                        unobtrusive
                      </FeatureImprovement>
                      <FeatureImprovement>
                        We have ramped up performance throughout the application
                      </FeatureImprovement>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Flex>
          </Container>

          {/* Version 1 */}
          <GradientWrapper pt={6}>
            <Container>
              <Box px={[0, 3]} mb={4}>
                <Box>
                  <h2
                    style={{
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      marginRight: '16px'
                    }}>
                    Version 1 &rarr; 1.13
                  </h2>
                </Box>
                <p className="subtitle">
                  Noteworthy features and improvements we made to version 1 in
                  no particular order
                </p>
              </Box>
              <Flex mb={6}>
                <Box width={[1, 1 / 2]} px={[0, 3]}>
                  <ul>
                    <li>Native support for Dark Mode</li>
                    <li>
                      QuickDrag which allows you to change dimensions and file
                      types on the fly
                    </li>
                    <li>
                      A complete redesigns of many features such as our backup
                      flow and our toolbar
                    </li>
                    <li>A way to replace one or multiple icons</li>
                    <li>Finder tags support</li>
                    <li>
                      Support for numerous file types such as EPS, PDF and ICO
                    </li>
                    <li>A way to import multiple .iconjar files at once</li>
                    <li>A way to assign a license to icons, sets and groups</li>
                  </ul>
                </Box>
                <Box width={[1, 1 / 2]} px={[0, 3]}>
                  <ul>
                    <li>A Sketch plugin that to import icons into IconJar</li>
                    <li>A rich export feature</li>
                    <li>Built-in support for FontAwesome 5</li>
                    <li>
                      A custom URL-scheme that automatically downloads icon sets
                      from the web and imports them into IconJar
                    </li>
                    <li>Custom export presets</li>
                    <li>
                      An interactive breadcrumb so you can easily navigate
                      through your folder structure
                    </li>
                    <li>Built-in support for the Google Material icons</li>
                    <li>
                      Endless amounts of SVG improvements such as "copy as
                      formatted SVG code"
                    </li>
                  </ul>
                </Box>
              </Flex>
            </Container>
          </GradientWrapper>
        </>
      )}
    </ThemeConsumer>
  )
}

export default Changelog

export const pageQuery = graphql`
  query {
    SearchProperties: file(relativePath: { eq: "updates/iconjar-2.3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 726, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    RegEx: file(relativePath: { eq: "updates/iconjar-2.4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 726, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Bups: file(relativePath: { eq: "updates/iconjar-2.5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 726, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    SVGCleaner: file(relativePath: { eq: "updates/iconjar-2.6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 726, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    BigSur: file(relativePath: { eq: "updates/iconjar-2.7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 726, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    twoTen: file(relativePath: { eq: "updates/iconjar-2.10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 726, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    currentColor: file(relativePath: { eq: "updates/currentColor.png" }) {
      childImageSharp {
        fluid(maxWidth: 726, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
