import React from 'react'
import styled from 'styled-components'
import { Box } from 'reflexbox'

const StyledBadge = styled(Box)`
  -webkit-appearance: none;
  display: inline-block;
  vertical-align: middle;
  padding: 0.25rem 0.75rem;
  font-weight: 600;
  font-size: 0.85rem;
  border-radius: 100px;
  background: ${props =>
    props.colorscheme === 'green'
      ? 'rgba(119, 202, 52, .12)'
      : props.colorscheme === 'yellow'
      ? 'rgba(253, 174, 56, .07)'
      : props.colorscheme === 'orange'
      ? 'rgba(253, 118, 56, .07)'
      : props.colorscheme === 'red'
      ? 'rgba(255, 118, 117, .07)'
      : props.colorscheme === 'purple'
      ? 'rgba(114, 73, 232, .07)'
      : 'rgba(1, 122, 255, .07)'};
  color: ${props =>
    props.colorscheme === 'green'
      ? '#77CA34'
      : props.colorscheme === 'yellow'
      ? '#FDAE38'
      : props.colorscheme === 'orange'
      ? '#FD7638'
      : props.colorscheme === 'red'
      ? '#FF7675'
      : props.colorscheme === 'purple'
      ? '#7249E8'
      : '#16A5F3'};
`

const Badge = props => {
  return <StyledBadge {...props}>{props.children}</StyledBadge>
}

export default Badge
