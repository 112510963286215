import React from 'react'
import styled from 'styled-components'
import IconBug from '../images/vector/icon-bug.svg'
import { Flex, Box } from 'reflexbox'

const StyledCallout = styled(Flex)`
  * {
    display: inline-block;
    vertical-align: middle;
  }

  p {
    margin-bottom: 0;
  }
`

const BugFix = props => {
  return (
    <StyledCallout {...props} mb={2}>
      <Box style={{ minWidth: '22px', marginRight: '1em' }}>
        <IconBug />
      </Box>
      <Box>
        <p>{props.children}</p>
      </Box>
    </StyledCallout>
  )
}

export default BugFix
