import React from 'react'
import styled from 'styled-components'
import { Box } from 'reflexbox'

const StyledGradientWrapper = styled(Box)`
  background: ${props =>
    props.theme.appearance === 'light'
      ? 'linear-gradient(180deg, #F2F9FE 0%, #FFF 100%)'
      : 'linear-gradient(180deg, #2B2C2D 0%, #343639 100%)'};
  background-size: 100% 500px;
  background-repeat: no-repeat;
`

const GradientWrapper = props => {
  return (
    <StyledGradientWrapper {...props}>{props.children}</StyledGradientWrapper>
  )
}

export default GradientWrapper
