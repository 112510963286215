import React from 'react'
import styled from 'styled-components'
import IconImprovement from '../images/vector/icon-improvement.svg'
import { Flex, Box } from 'reflexbox'

const StyledCallout = styled(Flex)`
  p,
  svg {
    display: inline-block;
    vertical-align: middle;
  }

  p {
    margin-bottom: 0;
  }
`

const FeatureImprovement = props => {
  return (
    <StyledCallout {...props} mb={2}>
      <Box style={{ minWidth: '22px', marginRight: '1em' }}>
        <IconImprovement />
      </Box>
      <Box>
        <p>{props.children}</p>
      </Box>
    </StyledCallout>
  )
}

export default FeatureImprovement
